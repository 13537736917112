import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PropertyListComponent } from './property-list/property-list.component';
import { PropertyFormComponent } from './property-form/property-form.component';
import { AppAngularMaterialModule } from 'src/app/-angular-material/-angular-material.module';
import { DynamicFormModule } from '../shared/dynamicForm/dynamic-form.module';
import { PropertyCardComponent } from './property-card/property-card.component';
import { PropertyDetailComponent } from '../../views/property-detail/property-detail.component';

@NgModule({
  declarations: [PropertyListComponent, PropertyFormComponent, PropertyCardComponent, PropertyDetailComponent],
  imports: [
  CommonModule, AppAngularMaterialModule, DynamicFormModule
  ],
  exports: [
    PropertyListComponent, PropertyFormComponent, PropertyDetailComponent
  ]
})
export class PropertyModule { }
