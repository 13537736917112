import { Injectable } from '@angular/core';
import { FirebaseService } from 'src/app/services/firebase.service';
import { Observable } from 'rxjs';
import { AngularFirestoreDocument, QuerySnapshot, AngularFirestoreCollection } from '@angular/fire/firestore';
import { EditDialogComponent } from '../edit-dialog/edit-dialog.component';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { TaskListComponent } from '../tasks/task-list/task-list.component';
import { MatDialog } from '@angular/material';
import { ListDialogComponent } from '../list-dialog/list-dialog.component';
import { Property } from 'src/app/models/property';

@Injectable({
  providedIn: 'root'
})
export class PropertyService {
  propertyList: Observable<Property[]>;
  propColl = 'propertyCollectionRef';
  propertyCollectionRef: AngularFirestoreCollection;

  // Getters for the property
  getPropertyByUserId(userId: string): Promise<QuerySnapshot<Property>> {
    return this.fbServ.propertyCollectionRef.ref.where('owners', 'array-contains', userId).get() as Promise<QuerySnapshot<Property>>;
  }


  // Manupulate the property(ies)
  addProperty(propertyItem: Property) {
    const plainObject = { ...propertyItem };
    // add a then to do something with the new property item
    this.fbServ.addItemToCollection(plainObject, this.propColl);
  }
  editProperty(invData: Property) {
    this.fbServ.updateItemInCollection(invData.id, this.propColl, invData);
  }
  removeProperty(invData: Property) {
    this.fbServ.removeItemFromCollection(invData.id, this.propColl);
  }
  edit(prop: AngularFirestoreDocument) {
    const editDialog = this.dialog.open(EditDialogComponent, {
      width: '80%',
      height: '80vh',
      data: {
        formName: 'property',
        title: 'Edit Property',
        item: prop,
        isHomeDialog: true
      }
    });

    editDialog.afterClosed().subscribe(updatedData => {
      if (updatedData) {
        this.editProperty(updatedData);
      }
    });
  }
  delete(prop: Property) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Are you sure you want to delete ?'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.removeProperty(prop);
      }
    });
  }

  // INVENTORY
  editPropertyInventory(prop: Property) {
    const editDialog = this.dialog.open(ListDialogComponent, {
      width: '90%',
      height: '90vh',
      maxWidth: '90vw',
      data: {
        listType: 'inventory',
        title: 'Inventory for Property',
        item: prop
      }
    });

    editDialog.afterClosed().subscribe(updatedData => {
      return null; // temporary until we code this for real
      if (updatedData) {
        this.editProperty(updatedData);
      }
    });
  }

  // TASKS
  editPropertyTasks(prop: Property) {
    const listDialog = this.dialog.open(ListDialogComponent, {
      width: '80%',
      height: '80vh',
      data: { listType: 'task', title: 'Tasks for Property', item: prop }
    });

    listDialog.afterClosed().subscribe(updatedData => {
      return null; // temporary until we code this for real
      if (updatedData) {
        this.editProperty(updatedData);
      }
    });
  }

  constructor(private fbServ: FirebaseService, private dialog: MatDialog) {
    this.propertyList = this.fbServ.properties;
    this.propertyCollectionRef = this.fbServ.propertyCollectionRef;
  }
}
