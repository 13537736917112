import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { DocumentData } from '@angular/fire/firestore';
import { PropertyService } from '../property.service';
import { MatDialog } from '@angular/material';
import { Property } from 'src/app/models/property';
import { EditDialogComponent } from '../../edit-dialog/edit-dialog.component';

@Component({
  selector: 'g-property-list',
  templateUrl: './property-list.component.html',
  styleUrls: ['./property-list.component.scss']
})
export class PropertyListComponent implements OnInit {
  @Input() propertyList: Observable<DocumentData[]>;

  addNew() {
    const newPropertyFormItem = new Property();
    const editDialog = this.dialog.open(EditDialogComponent, {
      width: '80%',
      height: '80vh',
      data: { formName: 'property', title: 'Add New Property', item: newPropertyFormItem }
    });
    editDialog.afterClosed().subscribe((newProperty: Property) => {
      delete newProperty.id;
      this.propServ.addProperty(newProperty);
    });
  }

  constructor(private propServ: PropertyService, private dialog: MatDialog) { }

  ngOnInit() {
    if (!this.propertyList) {
      this.propertyList = this.propServ.propertyList;
    }
  }

}
