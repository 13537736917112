import { ValidatorFn } from "@angular/forms";

export class FormField {
    
    constructor(public key: string, public label?: string, public value?: string, public type?: string,
        public inputType?: string, public options?: {}[], public validation?: ValidatorFn | ValidatorFn[],
        public order?: number, public disabled?: boolean) {
        
            if (!key || key.length === 0) return;
            this.value = value;
            this.type = type || 'input';
            this.inputType = inputType || '';
            this.label = label || key.charAt(0).toUpperCase() + key.slice(1);
            this.validation = validation || null;
            this.options = options || [];
            this.order = order || 0;
            this.disabled = disabled || false;
    }
}
