import { DynamicFormModule } from './../../shared/dynamicForm/dynamic-form.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContractorListComponent } from '../contractor-list/contractor-list.component';
import { AppAngularMaterialModule } from 'src/app/-angular-material/-angular-material.module';

@NgModule({
  declarations: [
    ContractorListComponent
  ],
  imports: [
    CommonModule, AppAngularMaterialModule, DynamicFormModule
  ],
  exports: [
    ContractorListComponent
  ]
})
export class ContractorModule { }
