import { FormField } from "./form-field";

export class Inventory {
  id?: string;
  propertyId?: FormField;
  name: FormField;
  brand: FormField;
  modelNumber: FormField;
  modelName: FormField;
  type: FormField;
  purchaseDate: FormField;
  location: FormField;
  schedule: FormField;
  constructor(
    id?: string,
    propertyId?: string,
    name?: string,
    brand?: string,
    modelNumber?: string,
    modelName?: string,
    type?: string,
    purchaseDate?: string,
    location?: string,
    schedule?: string
  ) {
    this.id = id || "";
    this.propertyId = new FormField(
      "propertyId",
      "Where is it?",
      propertyId || null,
      "select",
      null,
      []
    );
    this.name = new FormField("name", "Name", name || "");
    this.brand = new FormField("brand", "Brand", brand || "");
    this.modelName = new FormField("modelName", "Model Name", modelName || "");
    this.modelNumber = new FormField(
      "modelNumber",
      "Model Number",
      modelNumber || ""
    );
    this.type = new FormField("type", "Type", type || "");
    this.purchaseDate = new FormField(
      "purchaseDate",
      "Purchase Date",
      purchaseDate || null,
      "datepicker"
    );
    this.location = new FormField(
      "location",
      "Location",
      location || "",
      "select"
    );
    this.schedule = new FormField("schedule", "Schedule", schedule || "");
  }
}
