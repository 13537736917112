import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { GroupByPipe } from '../../shared/pipes/group-by.pipe';


import { TaskListComponent } from './../tasks/task-list/task-list.component';
import { TaskCardComponent } from './../tasks/task-card/task-card.component';
import { TaskFormComponent } from './../tasks/task-form/task-form.component';
import { DynamicFormModule } from '../shared/dynamicForm/dynamic-form.module';
import { AppAngularMaterialModule } from '../../-angular-material/-angular-material.module';
import { TaskCompletionComponent } from './task-completion/task-completion.component';
import { VerificationComponent } from './verification/verification.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    TaskCardComponent,
    TaskFormComponent,
    TaskListComponent,
    TaskCompletionComponent,
    VerificationComponent,
    GroupByPipe
  ],
  imports: [
  CommonModule, AppAngularMaterialModule, DynamicFormModule,
    ReactiveFormsModule
  ],
  exports: [
    TaskCardComponent,
    TaskFormComponent,
    TaskListComponent,
    TaskCompletionComponent
  ],
  entryComponents: [
    TaskListComponent, VerificationComponent
  ]

})
export class TaskModule { }
