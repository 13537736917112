import { Component, OnInit, Input } from '@angular/core';
import { AngularFirestoreDocument } from '@angular/fire/firestore';
import { EditDialogComponent } from '../../edit-dialog/edit-dialog.component';
import { Task } from 'src/app/models/task';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { TaskService } from '../task.service';
import { FormService } from 'src/app/services/form.service';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'g-task-card',
  templateUrl: './task-card.component.html',
  styleUrls: ['./task-card.component.scss']
})
export class TaskCardComponent implements OnInit {
  @Input() taskDocument;

  // // Manupulate the task
  // edit(prop: AngularFirestoreDocument) {
  //   const editDialog = this.dialog.open(EditDialogComponent, {
  //     width: '80%',
  //     height: '80vh',
  //     data: { formName: 'task', title: 'Edit Task', item: prop }
  //   });

  //   editDialog.afterClosed().subscribe(updatedData => {
  //     if (updatedData) { this.propServ.editTask(updatedData); }
  //   });
  // }
  // delete(prop: Task) {
  //   const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
  //     width: '350px',
  //     data: "Are you sure you want to delete ?"
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result) { this.propServ.removeTask(prop); }
  //   });
  // }
  // //Manipulate the task collections (Task/Inventory)
  // completeTask(taskID: string) {

  // }

  edit(task: Task) {
    this.taskServ.edit(task);
  }
  delete(task: Task) {
    this.taskServ.delete(task);
  }

  constructor(private dialog: MatDialog, public taskServ: TaskService) {}

  ngOnInit() {}
}
