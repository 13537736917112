import { Component, OnInit, Input } from "@angular/core";
import { MatDialog } from "@angular/material";
import { InventoryService } from "../inventory.service";
import { EditDialogComponent } from "../../edit-dialog/edit-dialog.component";
import { Inventory } from "src/app/models/inventory";
import { ConfirmationDialogComponent } from "../../shared/confirmation-dialog/confirmation-dialog.component";

@Component({
  selector: "g-inventory-card",
  templateUrl: "./inventory-card.component.html",
  styleUrls: ["./inventory-card.component.scss"]
})
export class InventoryCardComponent implements OnInit {
  @Input() inventoryDocument: Inventory;

  edit(inv: Inventory) {
    const editDialog = this.dialog.open(EditDialogComponent, {
      width: "80%",
      height: "80vh",
      data: { formName: "inventory", title: "Edit Inventory", item: inv }
    });

    editDialog.afterClosed().subscribe(updatedData => {
      if (updatedData) {
        this.invServ.editInventory(updatedData);
      }
    });
  }
  delete(inv: Inventory) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "350px",
      data: "Are you sure you want to delete?"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.invServ.removeInventory(inv);
      }
    });
  }

  viewTasks() {
    this.invServ.showTasks(this.inventoryDocument);
  }

  constructor(private dialog: MatDialog, private invServ: InventoryService) {}

  ngOnInit() {}
}
