import { Injectable } from '@angular/core';
import {
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  AngularFirestore
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { User } from 'firebase';
import { AngularFireAuth } from '@angular/fire/auth';
import { map, filter } from 'rxjs/operators';
import { Property } from '../models/property';
import { Task } from '../models/task';
import { Inventory } from '../models/inventory';
import { Inspection } from '../models/inspection';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  propertyCollectionRef: AngularFirestoreCollection;
  properties: Observable<any[]>;
  inventoryCollectionRef: AngularFirestoreCollection;
  inventory: Observable<any[]>;
  taskCollectionRef: AngularFirestoreCollection;
  tasks: Observable<any[]>;
  inspectionCollectionRef: AngularFirestoreCollection;

  getUser(): Observable<User> {
    // TODO: get the user from the users collection instead
    return this.afAuth.user;
  }
  fixDatesForUpdate(dataObject: {}) {
    Object.keys(dataObject).forEach(key => {
      if (
        typeof dataObject[key] === 'object' &&
        Object.prototype.toString.call(dataObject[key]) === '[object Date]'
      ) {
        dataObject[key] = firebase.firestore.Timestamp.fromDate(
          dataObject[key]
        );
      }
    });
    return dataObject;
  }

  async getPropertyById(propertyID: string) {
    return this.propertyCollectionRef.snapshotChanges().pipe(
      map(props =>
        props.map(prop => ({
          id: prop.payload.doc.id,
          ...prop.payload.doc.data()
        }))
      ),
      filter(prop => prop['id'] === propertyID)
    );
  }

  async removeItemFromCollection(itemID: string, collectionName: string) {
    const itemCollection = this[collectionName];
    const itemRef: AngularFirestoreDocument = itemCollection.doc(itemID);
    return itemRef.delete();
  }

  async addItemToCollection(itemDocument: {}, collectionName: string) {
    // Will return a document id
    const itemCollection: AngularFirestoreCollection = this[collectionName];
    return itemCollection.add(itemDocument);
  }

  async updateItemInCollection(
    itemID: string,
    collectionName: string,
    newData: {}
  ) {
    newData = this.fixDatesForUpdate(newData);
    const itemCollection: AngularFirestoreCollection = this[collectionName];
    return itemCollection.doc(itemID).update(newData);
  }

  constructor(public db: AngularFirestore, private afAuth: AngularFireAuth) {
    this.inventoryCollectionRef = db.collection<Inventory>('inventory');
    this.taskCollectionRef = db.collection<Task>('tasks');
    this.propertyCollectionRef = db.collection<Property>('properties');
    this.inspectionCollectionRef = db.collection<Inspection>('inspections');
    this.properties = this.propertyCollectionRef.snapshotChanges().pipe(
      map(props =>
        props.map(prop => ({
          id: prop.payload.doc.id,
          ...prop.payload.doc.data()
        }))
      )
    );
    this.tasks = this.taskCollectionRef.snapshotChanges().pipe(
      map(props =>
        props.map(prop => ({
          id: prop.payload.doc.id,
          ...prop.payload.doc.data()
        }))
      )
    );
    this.inventory = this.inventoryCollectionRef.snapshotChanges().pipe(
      map(props =>
        props.map(prop => ({
          id: prop.payload.doc.id,
          ...prop.payload.doc.data()
        }))
      )
    );
  }
}
