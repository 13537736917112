import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class AuthGuard {
  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.authenticated.pipe(map(e => {
      console.log(e);
      if (e) {
        return true;
      } else {
        this.router.navigate(['/login']);
        return false;
      }
    }),
      catchError((err: any) => {
        this.router.navigate(['/login']);
        return of(false);
      })
    );
  }
}
