import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Property } from 'src/app/models/property';

interface ListDialogData {
  listType: string;
  item: Property; // parent item, usually property
  title: string;
  isHomeDialog?: boolean; // probably redundant now that we are passing in the whole property
  property?: Property;
}

@Component({
  selector: 'g-list-dialog',
  templateUrl: './list-dialog.component.html',
  styleUrls: ['./list-dialog.component.scss']
})
export class ListDialogComponent implements OnInit {
  listType: string; // which list are we showing
  property: Property;

  close() {
    this.dialogRef.close(null);
  }

  constructor(
    public dialogRef: MatDialogRef<ListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ListDialogData
  ) {}

  ngOnInit() {
    this.listType = this.data.listType;
    this.property = this.data.item || null;
  }
}
