import { UserLayoutComponent } from './layouts/user/user-layout.component';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { LoginComponent } from './views/login/login.component';

import { AppComponent } from './app.component';

import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule } from './shared/navbar/navbar.module';
import { FixedpluginModule } from './shared/fixedplugin/fixedplugin.module';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';

import { AppRoutes } from './app.routing';
import { AppAngularFireModule } from './-angular-fire/-angular-fire.module';
import { AppAngularMaterialModule } from './-angular-material/-angular-material.module';
import { DynamicFormModule } from './components/shared/dynamicForm/dynamic-form.module';
import { ComponentsModule } from './components/components.module';
import { InventoryModule } from './components/inventory/inventory.module';
import { PropertyModule } from './components/property/property.module';
import { TaskModule } from './components/tasks/task.module';
import { EditDialogComponent } from './components/edit-dialog/edit-dialog.component';
import { ListDialogComponent } from './components/list-dialog/list-dialog.component';
import { ConfirmationDialogComponent } from './components/shared/confirmation-dialog/confirmation-dialog.component';
import { TaskCompletionComponent } from './components/tasks/task-completion/task-completion.component';
import { ContractorModule } from './components/contractors/contractor/contractor.module';
import { CardLoginComponent } from './components/cards/card-login/card-login.component';
import { HomeComponent } from './views/home/home.component';
import { LogoutComponent } from './views/logout/logout.component';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  imports: [
  CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(AppRoutes),
    HttpClientModule,
    SidebarModule,
    NavbarModule,
    FooterModule,
    FixedpluginModule,
    AppAngularFireModule,
    AppAngularMaterialModule,
    DynamicFormModule,
    ComponentsModule,
    InventoryModule,
    PropertyModule,
    TaskModule,
    ContractorModule
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    UserLayoutComponent,
    AuthLayoutComponent,
    HomeComponent,
    LoginComponent,
    LogoutComponent,
    CardLoginComponent,
    EditDialogComponent,
    ConfirmationDialogComponent,
  ],
  providers: [],
  entryComponents: [
    EditDialogComponent,
    ListDialogComponent,
    ConfirmationDialogComponent,
    TaskCompletionComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
