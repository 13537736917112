import { Component, OnInit, Input, Inject } from '@angular/core';
import { Task } from 'src/app/models/task';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'g-task-completion',
  templateUrl: './task-completion.component.html',
  styleUrls: ['./task-completion.component.scss']
})
export class TaskCompletionComponent implements OnInit {
  task: Task;
  completionDate: Date;

  verificationForm = this.fb.group({
    completionDate: [new Date()]
  });
  
  constructor(private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.task = data.selectedTask;
   }

  ngOnInit() {
    this.completionDate = new Date('1/3/2019');
    this.verificationForm.valueChanges.subscribe(formVals => {
      this.completionDate = formVals.completionDate;
    });
  }

}
