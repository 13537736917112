import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { FormField } from 'src/app/models/form-field';
import { AngularFirestoreDocument } from '@angular/fire/firestore';
import { FormGroup } from '@angular/forms';
import { Property } from 'src/app/models/property';
import { map } from 'rxjs/operators';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'g-property-form',
  templateUrl: './property-form.component.html',
  styleUrls: ['./property-form.component.scss']
})
export class PropertyFormComponent implements OnInit {
  @ViewChild('propForm') propForm; // I don't think we're using this

  config: FormField[];
  @Input() formData: AngularFirestoreDocument;
  @Output() formDataChange: EventEmitter<FormGroup> = new EventEmitter();
  @Output() submitted: EventEmitter<FormGroup> = new EventEmitter();
  
  formSubmitted(evt) { // not used
    this.submitted.emit(evt.value);
  }
  formValueChanged(evt) {
    console.log('Property Form detected value change');
    this.formDataChange.emit(evt);
  }
  async loadFormValues(propObj: Property, formValues: {}) {
    let inventory = await this.fbServ.propertyCollectionRef.get().pipe(
      map((prop) => {
        return prop.docs.map(i => {
          return {value: i.id,
            label: i.data().name || i.data().brand && i.data().location
          }
        });
      })
    ).toPromise();
    Object.keys(propObj).forEach((key: string) => {
      if (typeof propObj[key] === 'object') {
        propObj[key].value = (formValues && formValues[key]) && typeof formValues[key] !== 'object'
        ? formValues[key] : null;
        this.config.push(propObj[key]);
      }
    });
  }

  constructor(private fbServ: FirebaseService) {
    this.config = [];
  }

  ngOnInit() {
    const propObj = new Property();
    if (!this.formData) {
      this.loadFormValues(propObj, {});
    } else {
      this.loadFormValues(propObj, this.formData);
    }
  }
}
