import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormField } from "src/app/models/form-field";
import { Inventory } from "src/app/models/inventory";
import {
  AngularFirestoreDocument,
  DocumentData
} from "@angular/fire/firestore";
import { FormController } from "../../shared/dynamicForm/form.controller";
import { FormGroup } from "@angular/forms";

@Component({
  // tslint:disable-next-line: component-selector
  selector: "g-inventory-form",
  templateUrl: "./inventory-form.component.html",
  styleUrls: ["./inventory-form.component.scss"]
})
export class InventoryFormComponent implements OnInit {
  config: FormField[] = [];
  // The next two lines allow for banna in a box format
  @Input() formData: AngularFirestoreDocument;
  @Output() formDataChange: EventEmitter<FormGroup> = new EventEmitter();
  @Output() submitted: EventEmitter<FormGroup> = new EventEmitter();

  formSubmitted(evt) {
    // not used
    this.submitted.emit(evt.value);
  }
  formValueChanged(evt) {
    console.log("Inventory Form detected value change");
    this.formDataChange.emit(evt);
  }
  constructor(private formController: FormController) {}

  ngOnInit() {
    const invObj = new Inventory();
    const formData: AngularFirestoreDocument<DocumentData> | {} =
      this.formData || {};
    this.formController.sourceObject = invObj;
    this.formController.formValues = formData;
    this.config = this.formController.config = []; // reset this each time
    this.formController.loadFormValues();
  }
}
