import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DynamicFormComponent } from "./containers/dynamic-form/dynamic-form.component";
import { FormButtonComponent } from "./components/form-button/form-button.component";
import { FormInputComponent } from "./components/form-input/form-input.component";
import { FormSelectComponent } from "./components/form-select/form-select.component";
import { DynamicFieldDirective } from "./components/dynamic-field.directive";
import { AppAngularMaterialModule } from "src/app/-angular-material/-angular-material.module";
import { FormDatePickerComponent } from "./components/form-date-picker/form-date-picker.component";

@NgModule({
  declarations: [
    DynamicFieldDirective,
    DynamicFormComponent,
    FormButtonComponent,
    FormInputComponent,
    FormSelectComponent,
    FormDatePickerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppAngularMaterialModule
  ],
  exports: [
    DynamicFieldDirective,
    DynamicFormComponent,
    FormButtonComponent,
    FormInputComponent,
    FormSelectComponent,
    FormDatePickerComponent
  ],
  entryComponents: [
    FormButtonComponent,
    FormInputComponent,
    FormSelectComponent,
    FormDatePickerComponent
  ]
})
export class DynamicFormModule {}
