import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "g-form-date-picker",
  templateUrl: "./form-date-picker.component.html",
  styleUrls: ["./form-date-picker.component.scss"]
})
export class FormDatePickerComponent implements OnInit {
  config;
  group: FormGroup;
  pickerName: string;
  startDate: Date;
  convertTimestampToDate() {
    this.config.value = new Date(this.config.value);
  }

  constructor() {
    this.pickerName = "x";
    this.startDate = new Date(); // TODO: or use the value
  }

  ngOnInit() {
    this.pickerName = "picker-" + this.pickerName.toLowerCase();
    this.convertTimestampToDate();
  }
}
