import { FormField } from './form-field';

export class Task {
  id?: string;
  propertyId?: FormField;
  inventoryId?: FormField;
  name: FormField;
  description: FormField;
  location: FormField;
  triggerFrequency: FormField;
  triggerType: FormField; // single-date-driven, seasonal, recurring
  schedule: FormField; // e.g if seasonal=>spring, summer, etc. if recurring, every x number of "intervals"
  scheduledDate: FormField;
  dueDate: FormField;
  completedDate: FormField;
  constructor(
    id?: string,
    propertyId?: string,
    inventoryId?: string,
    name?: string,
    description?: string,
    triggerFrequency?: string,
    triggerType?: string,
    completedDate?: string,
    dueDate?: string,
    location?: string,
    schedule?: string,
    scheduledDate?: string
  ) {
    this.id = id || '';
    this.propertyId = new FormField(
      'propertyId',
      'Where is it?',
      propertyId || null,
      'select',
      null,
      []
    );
    this.inventoryId = new FormField(
      'inventoryId',
      'What are you working on?',
      inventoryId || null,
      'select',
      null,
      []
    );
    this.name = new FormField('name', 'Name', name || '');
    this.description = new FormField(
      'description',
      'Description',
      description || ''
    );
    this.triggerType = new FormField(
      'triggerType',
      'How is this scheduled?',
      triggerType || ''
    );
    this.triggerFrequency = new FormField(
      'triggerFrequency',
      'Trigger Frequency',
      triggerFrequency || ''
    );
    this.completedDate = new FormField(
      'completedDate',
      'Completed Date',
      completedDate || '',
      'datepicker'
    );
    this.dueDate = new FormField(
      'dueDate',
      'Due Date',
      dueDate || null,
      'datepicker'
    );
    this.location = new FormField('location', 'Location', location || '');
    this.schedule = new FormField('schedule', 'Schedule', schedule || '');
    this.scheduledDate = new FormField(
      'scheduledDate',
      'Scheduled Date',
      scheduledDate || null,
      'datepicker'
    );
  }
}
