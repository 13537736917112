import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { Component, OnInit, ElementRef, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-card-login',
  templateUrl: './card-login.component.html',
  styleUrls: ['./card-login.component.scss']
})
export class CardLoginComponent implements OnInit {

  googleLogin(evt) {
    this.auth.login('google').then(res => {
      this.router.navigate(['/home']);
    }).catch(e => {
      // TODO: Show error message
    });
  }
  facebookLogin(evt) {
    this.auth.login('facebook').then(res => {
      this.router.navigate(['/home']);
    }).catch(e => {
      // TODO: Show error message
    });
  }

  constructor(public auth: AuthService, private router: Router) { }

  ngOnInit() {
  }

}
