import { Injectable } from '@angular/core';
import { FirebaseService } from 'src/app/services/firebase.service';
import { Inventory } from 'src/app/models/inventory';
import {
  AngularFirestoreCollection,
  AngularFirestore
} from '@angular/fire/firestore';
import { ListDialogComponent } from '../list-dialog/list-dialog.component';
import { MatDialog } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {
  fbDB: AngularFirestore;
  inventoryList: AngularFirestoreCollection;
  invColl = 'inventoryCollectionRef';

  addInventory(inventoryItem: Inventory) {
    const plainObject = { ...inventoryItem };
    // add a then to do something with the new inventory item
    this.fbServ.addItemToCollection(plainObject, this.invColl);
  }
  editInventory(invData: Inventory) {
    this.fbServ.updateItemInCollection(invData.id, this.invColl, invData);
  }
  removeInventory(invData: Inventory) {
    this.fbServ.removeItemFromCollection(invData.id, this.invColl);
  }

  showTasks(inventoryItem: Inventory) {
    this.fbServ
      .getPropertyById(inventoryItem.propertyId.value)
      .then(parentProperty => {
        const listDialog = this.dialog.open(ListDialogComponent, {
          width: '80%',
          height: '80vh',
          data: {
            listType: 'task',
            title: 'Tasks for Property',
            item: parentProperty
          }
        });

        listDialog.afterClosed().subscribe(updatedData => {
          if (updatedData) {
            this.editInventory(updatedData);
          }
        });
      });
  }
  constructor(private dialog: MatDialog, private fbServ: FirebaseService) {
    this.inventoryList = this.fbServ.inventoryCollectionRef;
    this.fbDB = this.fbServ.db;
  }
}
