import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FirebaseService } from 'src/app/services/firebase.service';
import { InventoryListComponent } from './inventory-list/inventory-list.component';
import { InventoryFormComponent } from './inventory-form/inventory-form.component';
import { DynamicFormModule } from '../shared/dynamicForm/dynamic-form.module';
import { AppAngularMaterialModule } from 'src/app/-angular-material/-angular-material.module';
import { InventoryCardComponent } from './inventory-card/inventory-card.component';

@NgModule({
  declarations: [InventoryListComponent, InventoryFormComponent, InventoryCardComponent],
  imports: [
    CommonModule, DynamicFormModule, AppAngularMaterialModule
  ],
  exports: [
    InventoryListComponent, InventoryFormComponent
  ],
  entryComponents: [
    InventoryListComponent
  ]
})
export class InventoryModule {
  constructor(private fb: FirebaseService) { }
 }
