import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection
} from '@angular/fire/firestore';
import { Task } from 'src/app/models/task';
import { FirebaseService } from 'src/app/services/firebase.service';
import { EditDialogComponent } from '../edit-dialog/edit-dialog.component';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material';
import { TaskCompletionComponent } from './task-completion/task-completion.component';

@Injectable({
  providedIn: 'root'
})
export class TaskService {
  fbDB: AngularFirestore;
  taskList: AngularFirestoreCollection;
  taskColl = 'taskCollectionRef';

  private addTask(taskItem: Task) {
    const plainObject = { ...taskItem };
    // add a then to do something with the new task item
    this.fbServ.addItemToCollection(plainObject, this.taskColl);
  }
  private editTask(taskData: Task) {
    this.fbServ.updateItemInCollection(taskData.id, this.taskColl, taskData);
  }
  private removeTask(taskData: Task) {
    this.fbServ.removeItemFromCollection(taskData.id, this.taskColl);
  }

  addNew(propertyId?: string) {
    propertyId = propertyId || null;
    const newTaskFormItem = new Task('', propertyId, null, 'new task');
    const editDialog = this.dialog.open(EditDialogComponent, {
      width: '80%',
      height: '80vh',
      data: { formName: 'task', title: 'Add New Task', item: newTaskFormItem }
    });
    editDialog.afterClosed().subscribe((newTaskItem: Task) => {
      if (newTaskItem) {
        delete newTaskItem.id;
        this.addTask(newTaskItem);
      }
    });
  }

  // Manupulate the task
  edit(task: Task) {
    const editDialog = this.dialog.open(EditDialogComponent, {
      width: '80%',
      height: '80vh',
      data: { formName: 'task', title: 'Edit Task', item: task }
    });

    editDialog.afterClosed().subscribe(updatedData => {
      if (updatedData) {
        this.editTask(updatedData);
      }
    });
  }
  delete(task: Task) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Are you sure you want to delete ?'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.removeTask(task);
      }
    });
  }
  completeTask(task: Task) {
    // This is the VERIFY part
    this.dialog.open(TaskCompletionComponent, {
      minHeight: '80vh',
      minWidth: '80vw',
      data: {
        selectedTask: task
      }
    });
  }

  constructor(private fbServ: FirebaseService, private dialog: MatDialog) {
    this.taskList = this.fbServ.taskCollectionRef;
    this.fbDB = this.fbServ.db;
  }
}
