import {
  Component,
  OnInit,
  Input,
  Inject,
  Output,
  EventEmitter
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { AngularFirestoreDocument } from "@angular/fire/firestore";

interface EditDialogData {
  formName: string;
  item: AngularFirestoreDocument;
  title: string;
  isHomeDialog?: boolean;
}

@Component({
  // tslint:disable-next-line: component-selector
  selector: "g-edit-dialog",
  templateUrl: "./edit-dialog.component.html",
  styleUrls: ["./edit-dialog.component.scss"]
})
export class EditDialogComponent implements OnInit {
  // TODO: Break this out of the shared form
  @Input() isHomeDialog: boolean;
  @Output() inspectionButtonClick: EventEmitter<string> = new EventEmitter<
    string
  >();
  @Output() inventoryButtonClick: EventEmitter<string> = new EventEmitter<
    string
  >();
  // ---
  form: FormGroup;
  formData: any; // will be Inventory or Task or Property, etc.
  itemId: string; // Save this because we lose it in the form

  // TODO: also remove this functions
  startInspection() {
    this.inspectionButtonClick.emit(this.itemId);
  }
  viewInventory() {
    this.inventoryButtonClick.emit(this.itemId);
  }

  formSubmitted(evt) {
    // I don't think we'll use this
  }
  delete() {
    // TODO: Confirm deletion
    this.dialogRef.close({ action: "delete", id: this.itemId });
  }
  save() {
    // push the changed data
    this.formData["id"] = this.itemId;
    this.dialogRef.close(this.formData);
  }
  close() {
    this.dialogRef.close(null);
  }

  constructor(
    public dialogRef: MatDialogRef<EditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EditDialogData
  ) {}

  ngOnInit() {
    this.formData = this.data.item;
    this.itemId = this.data.item["id"];
    this.isHomeDialog = this.data.isHomeDialog || false;
    console.log(this.formData);
  }
}
