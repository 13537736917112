import { Injectable } from "@angular/core";
import { FormField } from "src/app/models/form-field";
import { AngularFirestoreDocument } from "@angular/fire/firestore";
import { map } from "rxjs/operators";
import { FirebaseService } from "src/app/services/firebase.service";

@Injectable({
  providedIn: "root"
})
export class FormController {
  sourceObject: {};
  formValues: AngularFirestoreDocument | {};
  config: FormField[] = [];

  async loadFormValues() {
    // For the properties dropdown menu
    const properties = await this.fbServ.propertyCollectionRef
      .get()
      .pipe(
        map(prop => {
          return prop.docs.map(p => {
            return {
              value: p.id,
              label: p.data().street1 || p.data().name
            };
          });
        })
      )
      .toPromise();
    // TODO:  need to filter this by the property Id!
    // For the task dropdown menu
    const prop = this.formValues["propertyId"];
    const startVal = typeof prop === "object" ? prop.value || "0" : prop || "0";
    const endVal = typeof prop === "object" ? prop.value || "~" : prop || "~";
    const taskItemsSnapshot = await this.fbServ.taskCollectionRef.ref
      .where("propertyId", ">=", startVal)
      .where("propertyId", "<=", endVal)
      .get();
    const taskItems = taskItemsSnapshot.docs.map(i => {
      const taskItem = i.data();
      return {
        value: i.id,
        label: taskItem.name || taskItem.modelName
      };
    });

    const inventoryItemsSnapshot = await this.fbServ.inventoryCollectionRef.ref
      .where("propertyId", ">=", startVal)
      .where("propertyId", "<=", endVal)
      .get();
    const inventoryItems = inventoryItemsSnapshot.docs.map(i => {
      const inventoryItem = i.data();
      return {
        value: i.id,
        label: inventoryItem.name || inventoryItem.modelName
      };
    });

    // Populate form
    Object.keys(this.sourceObject).forEach((key: string) => {
      if (typeof this.sourceObject[key] === "object") {
        if (key === "propertyId") {
          this.sourceObject[key].options = properties;
        }
        if (key === "inventoryId") {
          this.sourceObject[key].options = inventoryItems;
        }
        if (key === "taskId") {
          this.sourceObject[key].options = taskItems;
        }
        // add new function will just pass in a blank item with form data
        // so don't set the value if we're dealing with a "new form"
        const keyExists = this.formValues && this.formValues[key];
        if (keyExists) {
          const source = this.formValues[key];
          if (typeof source !== "object") {
            this.sourceObject[key].value = source;
          } else {
            if (source.seconds) {
              this.sourceObject[key].value = new Date(source.seconds * 1000);
            } else {
              this.sourceObject[key].value = source.value;
            }
          }
          console.log(key + " " + this.sourceObject[key].value);
        } else {
          this.sourceObject[key].value = null;
        }
        this.config.push(this.sourceObject[key]);
      }
    });
  }

  constructor(private fbServ: FirebaseService) {
    this.config = [];
  }
}
