import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AppAngularMaterialModule } from '../-angular-material/-angular-material.module';
import { InventoryModule } from './inventory/inventory.module';
import { TaskModule } from './tasks/task.module';

import { ButtonsComponent } from './buttons/buttons.component';
import { ComponentsRoutes } from './components.routing';
import { GridSystemComponent } from './grid/grid.component';
import { IconsComponent } from './icons/icons.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { PanelsComponent } from './panels/panels.component';
import { SweetAlertComponent } from './sweetalert/sweetalert.component';
import { TypographyComponent } from './typography/typography.component';
import { ListDialogComponent } from './list-dialog/list-dialog.component';
// import { FooterComponent } from '../shared/footer/footer.component';

@NgModule({
  imports: [
CommonModule,
    RouterModule.forChild(ComponentsRoutes),
    FormsModule,
    AppAngularMaterialModule,
    InventoryModule, TaskModule
  ],
  declarations: [
      ButtonsComponent,
      GridSystemComponent,
      IconsComponent,
      NotificationsComponent,
      PanelsComponent,
      SweetAlertComponent,
      TypographyComponent,
      ListDialogComponent,
      // FooterComponent
  ]
})

export class ComponentsModule {}
