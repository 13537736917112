import { Component, OnInit } from '@angular/core';
import { PropertyService } from '../../components/property/property.service';
import { Property } from 'src/app/models/property';
import { AuthService } from 'src/app/services/auth.service';
import { Subject } from 'rxjs';
import { QuerySnapshot } from '@angular/fire/firestore';

@Component({
  selector: 'g-property-detail',
  templateUrl: './property-detail.component.html',
  styleUrls: ['./property-detail.component.scss']
})
export class PropertyDetailComponent implements OnInit {
  property$: Subject<Property> = new Subject();

  constructor(private propServ: PropertyService, private authServ: AuthService) { }

  ngOnInit() {
    this.authServ.user$.subscribe(authUser => {
      const userId = authUser.uid;
      this.propServ.getPropertyByUserId(userId).then((properties: QuerySnapshot<Property>) => {
        const firstProperty = properties.docs[0];
        this.property$.next({ id: firstProperty.id, ...firstProperty.data() });
      });
    });
  }

}
