import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyAFp7jSBizvwfCr8iG9L2PihLuEoy7vMt8',
    authDomain: 'homemanager-76ad6.firebaseapp.com',
    databaseURL: 'https://homemanager-76ad6.firebaseio.com',
    projectId: 'homemanager-76ad6',
    storageBucket: 'homemanager-76ad6.appspot.com'
  }
};

@NgModule({
  imports: [
    CommonModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, AngularFireAuthModule,
  ],
  exports: [AngularFirestoreModule, AngularFireAuthModule,],
  declarations: [],
  providers: [
    AngularFirestoreModule, AngularFireAuthModule]
})
export class AppAngularFireModule { }
