import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { AuthService } from '../../services/auth.service';
// import dayGridPlugin from '@fullcalendar/daygrid';

@Component({
  selector: 'g-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {
  // @ViewChild('calendar', {static: false}) calEl;


  constructor(private auth: AuthService) { }

  ngOnInit() {
  }
  ngAfterViewInit() {
    // var calendarEl = this.calEl.nativeElement; // document.getElementById('calendar');

    // var calendar = new Calendar(calendarEl, {
    //   plugins: [dayGridPlugin]
    // });

    // calendar.render();
  }

}
