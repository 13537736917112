import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { DocumentData } from '@angular/fire/firestore';
import { Task } from 'src/app/models/task';
import { TaskService } from '../task.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'g-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss']
})
export class TaskListComponent implements OnInit {
  @Input() propertyId: string;
  @Input() taskList: Observable<DocumentData[]>;
  @Input() showAddNew: boolean;
  @Input() scheduled: boolean; // which list are we showing, due or already scheduled

  propertySelected: boolean; // used mostly in the view for show/hide and grouping

  getDate(ts: any) { // TODO get the correct type for firebase timestamp
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const newDate = new Date(parseInt(ts, 10));
    return `${monthNames[newDate.getMonth()]} ${newDate.getDate()}, ${newDate.getFullYear()}`;
  }

  addNew() {
    this.taskServ.addNew(this.propertyId);
  }

  constructor(public taskServ: TaskService) { }

  ngOnInit() {
    let startVal = this.propertyId;
    let endVal = this.propertyId;
    this.showAddNew = this.showAddNew || false;

    if (this.propertyId) {
      this.propertySelected = true;
    } else {
      startVal = '0'; // 0 is very low in the string order
      endVal = '~'; // tilde is very high in the string order
    }

    if (!this.taskList) {
      // you can pass in an task list, but I don't know if we'll ever want to
      const isScheduled = this.scheduled;
      this.taskList = this.taskServ.fbDB
        .collection('tasks', ref =>
          ref
            .where('propertyId', '>=', startVal)
            .where('propertyId', '<=', endVal)
        )
        .snapshotChanges()
        .pipe(
          map(props => {
            const filteredProps = props.filter(prop => {
              const taskInfo = prop.payload.doc.data() as Task;
              return ((!isScheduled && !taskInfo.scheduledDate) || (isScheduled && taskInfo.scheduledDate));
            });
            return filteredProps.map(prop => ({
              id: prop.payload.doc.id,
              ...prop.payload.doc.data() as Task
            })
            );
          })
        );
    }
  }
}
