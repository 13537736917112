import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { User, auth } from 'firebase/app';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user$: Observable<User>;
  user: User;
  userProfile: {}; // our customer user object

  async login(provider: string) {
    let providerObject: any;

    switch (provider) {
      case 'google':
        providerObject = new auth.GoogleAuthProvider();
        break;
      case 'facebook':
        providerObject = new auth.FacebookAuthProvider();
        break;
      default:
        providerObject = new auth.EmailAuthProvider();
        break;
    }

    const prm = new Promise((resolve, reject) => {
      this.afAuth.auth.signInWithPopup(providerObject).then(
        res => {
          // debugger;
          resolve(res);
        },
        e => {
          // debugger;
          console.log(e);
          if (
            e.code == 'auth/account-exists-with-different-credential' &&
            e.credential
          ) {
            this.afAuth.auth
              .fetchSignInMethodsForEmail(e.email)
              .then(providers => {
                let providerString: string;
                switch (providers[0]) {
                  case 'google.com':
                    providerString = 'google';
                    break;
                  case 'facebook.com':
                    providerString = 'facebook';
                    break;
                  default:
                    // manual email login
                    break;
                }
                this.login(providerString).then((originalCreds: any) => {
                  originalCreds.user.linkWithCredential(e.credential);
                  resolve(originalCreds);
                });
              })
              .catch(fetchError => console.log(fetchError));
            const prevUser = this.afAuth.auth.currentUser;

            // Sign in user with another account
            this.afAuth.auth
              .signInWithCredential(e.credential)
              .then(user => {
                // TODO: Merge the new data with the existing account

                // Delete the duplicate user
                return user.user
                  .delete()
                  .then(() => {
                    // Link the OAuth Credential to original account
                    return prevUser.linkWithCredential(e.credential);
                  })
                  .then(() => {
                    // Sign in with the newly linked credential
                    return this.afAuth.auth.signInWithCredential(e.credential);
                  });
              })
              .catch(error => {
                console.log('Sign in error!');
              });
          }
          if ((e.code = 'auth/web-storage-unsupported')) {
            // TODO: show snackbar that this browswer is not yet supported
          }
          reject(e);
        }
      );
    });
    return prm;
  }
  logout() {
    console.log('logging out');
    this.afAuth.auth.signOut();
    this.router.navigate(['/login']);
  }
  // Returns true if user is logged in
  get authenticated(): Observable<User> {
    return this.afAuth.authState.pipe(first());
    // return this.user !== null;
  }
  // Returns current user
  get currentUser(): any {
    return this.authenticated ? this.user : null;
  }

  // Returns current user UID
  get currentUserId(): string {
    return this.authenticated && this.user ? this.user.uid : '';
  }

  constructor(
    private afAuth: AngularFireAuth,
    private afDatabase: AngularFirestore,
    private router: Router
  ) {
    this.user$ = afAuth.user;
    afAuth.authState.subscribe((authUser: User) => {
      // when we get the user info from authUser, update our users db with the user information
      console.log('user', authUser);
      if (!authUser) {
        return null;
      }
      this.user = authUser;
      const userId = this.user.uid;
      const userRef = this.afDatabase.collection('users').doc(userId);
      userRef
        .update({ userType: null })
        .then(() => {
          this.userProfile = {};
        })
        .catch(err => {
          // TODO: Make sure the error is ID doesn't exist
          this.afDatabase
            .collection('users')
            .doc(userId)
            .set({
              email: this.user.email,
              userType: 1 // TODO: 1 is for Admin and 2 is for user...set this properly
            })
            .then(() => {
              console.log('new user added');
            })
            .catch(() => {
              console.log('Could not add user');
            });
        });
    });
  }
}
