import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { UserLayoutComponent } from './layouts/user/user-layout.component';
import { AuthGuard } from './services/auth-guard.service';
import { LoginComponent } from './views/login/login.component';
import { LogoutComponent } from './views/logout/logout.component';

export const AppRoutes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  {
    path: 'user',
    component: UserLayoutComponent,
    // canActivate: [AuthGuard],
    loadChildren: './dashboard-user/dashboard.user.module#DashboardUserModule'
  },
  {
    path: 'admin',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    loadChildren: './dashboard-admin/dashboard.module#DashboardModule',
  },
  {
    path: '',
    redirectTo: 'user',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'user',
    pathMatch: 'full'
  }
];
