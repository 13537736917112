import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { Inventory } from 'src/app/models/inventory';
import { InventoryService } from '../inventory.service';
import { DocumentData } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { EditDialogComponent } from '../../edit-dialog/edit-dialog.component';
import { PropertyService } from '../../property/property.service';
import { Property } from 'src/app/models/property';

@Component({
  selector: 'g-inventory-list',
  templateUrl: './inventory-list.component.html',
  styleUrls: ['./inventory-list.component.scss']
})
export class InventoryListComponent implements OnInit, OnChanges {
  @Input() propertyId: string;
  @Input() inventoryList: Observable<DocumentData[]>;
  @Input() showAddNew: boolean;

  propertySelected: boolean; // used mostly in the view for show/hide and grouping
  property: Property;

  init() {
    this.showAddNew = this.showAddNew || false;
    let startVal = this.propertyId;
    let endVal = this.propertyId;
    if (this.propertyId) {
      this.propertySelected = true;
      this.property = this.propServ.propertyList.subscribe(properties =>
        properties.filter(property => property.id === this.propertyId)
      )[0];
    } else {
      // grab all properties
      startVal = '0'; // 0 is very low in the string order
      endVal = '~'; // tilde is very high in the string order
    }
    // if (!this.inventoryList) {
    //  I don't know if we'll ever want to pass in an inventory List, so I commented this out
    // it was also breaking the form where if another property was selected, the list didn't change.
    this.inventoryList = this.invServ.fbDB
      .collection('inventory', ref => ref.where('propertyId', '==', startVal))
      .snapshotChanges()
      .pipe(
        // filter(invQuery(this.propertyId)),
        map(props => {
          return props.map(prop => {
            const propData: {} = prop.payload.doc.data();
            return {
              id: prop.payload.doc.id,
              ...propData
            };
          });
        })
      );
    // }
  }

  addNew() {
    const newInventoryFormItem = new Inventory('', this.propertyId, 'new item');
    const editDialog = this.dialog.open(EditDialogComponent, {
      width: '80%',
      height: '80vh',
      data: {
        formName: 'inventory',
        title: 'Add New Inventory',
        item: newInventoryFormItem
      }
    });

    editDialog.afterClosed().subscribe((newInventoryItem: Inventory) => {
      if (newInventoryItem) {
        delete newInventoryItem.id;
        this.invServ.addInventory(newInventoryItem);
      }
    });
  }

  constructor(
    private invServ: InventoryService,
    private propServ: PropertyService,
    private dialog: MatDialog
  ) {}

  ngOnChanges() {
    debugger;
    this.init();
  }

  ngOnInit() {
    this.init();
  }
}
