import { FormField } from './form-field';
import { States } from '../data/states';

export class Property {
    id?: string;
    name: FormField;
    street1: FormField;
    street2: FormField;
    city: FormField;
    state: FormField;
    zip: FormField;
    description: FormField;
    owners?: string[];
    imageURL: FormField;
    constructor(id?: string, name?: string, street1?: string, street2?: string,
        city?: string, state?: string, zip?: string, description?: string) {

        this.id = id || '';
        this.name = new FormField('name', 'name', name || '');
        this.street1 = new FormField('street1', 'Address', street1 || '');
        this.street2 = new FormField('street2', 'Address street2', street2 || '');
        this.city = new FormField('city', 'City', city || '');
        this.state = new FormField('state', 'State', state || '', 'select', null, States);
        this.zip = new FormField('zip', 'zip', zip || '');
        // this.owners = [];
        this.description = new FormField('description', 'Description', description || '');
    }
}
