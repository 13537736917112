import { Component, OnInit, Input } from "@angular/core";
import { PropertyService } from "../property.service";

@Component({
  selector: "g-property-card",
  templateUrl: "./property-card.component.html",
  styleUrls: ["./property-card.component.scss"]
})
export class PropertyCardComponent implements OnInit {
  @Input() propertyDocument;

  constructor(public propServ: PropertyService) {}

  ngOnInit() {}
}
