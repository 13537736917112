import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormField } from 'src/app/models/form-field';
import {
  AngularFirestoreDocument,
  DocumentData
} from '@angular/fire/firestore';
import { Task } from 'src/app/models/task';
import { FormController } from '../../shared/dynamicForm/form.controller';
import { FormGroup } from '@angular/forms';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'g-task-form',
  templateUrl: './task-form.component.html',
  styleUrls: ['./task-form.component.scss']
})
export class TaskFormComponent implements OnInit {
  config: FormField[];
  // The next two lines allow for banna in a box format
  @Input() formData: AngularFirestoreDocument;
  @Output() formDataChange: EventEmitter<FormGroup> = new EventEmitter();
  @Output() submitted: EventEmitter<FormGroup> = new EventEmitter();

  formSubmitted(evt) {
    // not used
    this.submitted.emit(evt.value);
  }
  formValueChanged(evt) {
    console.log('Task Form detected value change');
    this.formDataChange.emit(evt);
  }
  constructor(private formController: FormController) {
    this.config = [];
  }

  ngOnInit() {
    const taskObj = new Task();
    const formData: AngularFirestoreDocument<DocumentData> | {} =
      this.formData || {};
    this.formController.sourceObject = taskObj;
    this.formController.formValues = formData;
    this.config = this.formController.config = []; // reset this each time
    this.formController.loadFormValues();
  }
}
