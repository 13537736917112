import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupBy'
})
export class GroupByPipe implements PipeTransform {
  transform(collection: Array<any>, property: string): Array<any> {
      // prevents the application from breaking if the array of objects doesn't exist yet
      if (!collection) {
          return null;
      }

      const groupedCollection = collection.reduce((previous, current: {}) => {
          const propertyValue = current[property].seconds && typeof current[property].seconds === 'number' 
                              ? +new Date(current[property].seconds * 1000).setHours(0, 0, 0)
                              : current[property];

          if (!previous[propertyValue]) {
              previous[propertyValue] = [current];
          } else {
              previous[propertyValue].push(current);
          }

          return previous;
      }, {});


      // this will return an array of objects, each object containing a group of objects
      return Object.keys(groupedCollection).map(key => ({ key, value: groupedCollection[key] }));
  }
}
